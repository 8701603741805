<template>
  <div id="app" @click="closeAll($event)">
    <div>
      <header id="header">
        <div class="mobile">
          <img
            data-test="open-menu"
            @click="menuIsOpen = !menuIsOpen"
            src="@/assets/img/svg/icon-hamburger-btn.svg"
            alt=""
            class="hamb"
            :class="menuIsOpen ? 'active' : ''"
            :style="!hasPublicId ? 'opacity: 0; pointer-events: none;' : ''"
          />

          <img
            data-test="logo"
            @click.prevent="$router.push({ name: 'home' }).catch((err) => {})"
            src="@/assets/img/svg/ifood-logo-branco.svg"
            alt=""
            class="logo"
          />

          <img
            data-test="tag"
            @click.prevent="$router.push({ name: 'home' }).catch((err) => {})"
            src="@/assets/img/svg/tag.svg"
            class="tag"
          />

          <div class="right-wrapper">
            <div class="search-wrapper">
              <img
                data-test="header-mobile-open-search"
                src="@/assets/img/svg/icon-zoom.svg"
                @click="openSearch()"
                id="zoom"
                alt=""
              />
              <div class="search" v-if="showSearch">
                <b-input-group class="">
                  <b-form-input
                    data-test="header-mobile-search"
                    v-model="searchInput"
                    @keyup.13="doSearch()"
                    placeholder="Buscar produtos"
                  ></b-form-input>
                  <template #append>
                    <img
                      data-test="header-mobile-do-search"
                      src="@/assets/img/svg/icon-zoom-red.svg"
                      @click="doSearch()"
                      alt=""
                    />
                  </template>
                </b-input-group>
              </div>
            </div>

            <div class="minicart-wrapper" tabIndex="-1">
              <!-- <img data-test="header-mobile-open-cart-cmm" @click="$router.push({ name: 'checkout' })" src="@/assets/img/svg/icon-cart.svg" alt="" /> -->
              <img
                data-test="header-mobile-open-cart"
                v-if="!isCheckoutPage"
                @click="openCart()"
                src="@/assets/img/svg/icon-cart.svg"
                alt=""
                tabIndex="-1"
                :style="
                  !hasPublicId
                    ? 'opacity: 0; pointer-events: none; width: 0;'
                    : ''
                "
              />
            </div>
          </div>
        </div>

        <div class="desk lg" v-if="hasPublicId">
          <div class="left-wrapper">
            <img
              data-test="header-desk-logged-open-menu"
              @click="menuIsOpen = !menuIsOpen"
              src="@/assets/img/svg/icon-hamburger-btn.svg"
              alt=""
              class="hamb"
              :class="menuIsOpen ? 'active' : ''"
            />
          </div>

          <div class="center-wrapper">
            <img
              data-test="header-desk-logged-logo"
              @click.prevent="$router.push({ name: 'home' }).catch((err) => {})"
              src="@/assets/img/svg/ifood-logo-branco.svg"
              alt=""
              class="logo"
            />
          </div>

          <div class="right-wrapper">
            <div class="search">
              <b-input-group class="">
                <template #prepend>
                  <img
                    data-test="header-desk-logged-do-search"
                    src="@/assets/img/svg/icon-zoom-red.svg"
                    @click="doSearch()"
                    alt=""
                  />
                </template>
                <b-form-input
                  data-test="header-desk-logged-search"
                  v-model="searchInput"
                  @keyup.13="doSearch()"
                  placeholder="Buscar produtos"
                ></b-form-input>
              </b-input-group>
            </div>

            <div class="minicart-wrapper">
              <!-- <img data-test="header-desk-logged-open-cart-cmm" @click="$router.push({ name: 'checkout' })" src="@/assets/img/icon-cart.svg" alt="" /> -->
              <img
                data-test="header-desk-logged-open-cart"
                v-if="!isCheckoutPage"
                @click="openCart()"
                src="@/assets/img/svg/icon-cart.svg"
                alt=""
                :style="
                  !hasPublicId
                    ? 'opacity: 0; pointer-events: none; width: 0;'
                    : ''
                "
              />
            </div>
          </div>
        </div>

        <div class="desk nl" v-else>
          <img
            data-test="header-desk-notlogged-logo"
            @click.prevent="$router.push({ name: 'home' }).catch((err) => {})"
            src="@/assets/img/svg/ifood-logo-branco.svg"
            alt=""
            class="logo"
          />

          <div class="search">
            <b-input-group class="">
              <template #prepend>
                <img
                  data-test="header-desk-notlogged-do-search"
                  src="@/assets/img/svg/icon-zoom-red.svg"
                  @click="doSearch()"
                  alt=""
                />
              </template>
              <b-form-input
                data-test="header-desk-notlogged-search"
                v-model="searchInput"
                @keyup.13="doSearch()"
                placeholder="Buscar produtos"
              ></b-form-input>
            </b-input-group>
          </div>

          <div class="buttons">
            <a
              data-test="header-desk-notlogged-deliverydrivers"
              @click="
                $router.push({ name: 'ParaEntregadores' }).catch((err) => {})
              "
              class="btn"
              >Para Entregadores</a
            >
            <a
              data-test="header-desk-notlogged-restaurants"
              @click="
                $router.push({ name: 'ParaRestaurantes' }).catch((err) => {})
              "
              class="btn"
              >Para Restaurantes</a
            >
          </div>
        </div>
      </header>

      <div id="content">
        <router-view />
      </div>

      <b-sidebar
        tabIndex="-1"
        v-if="!isCheckoutPage"
        id="mycart"
        title="Carrinho"
        shadow
        right
        aria-hidden="false"
      >
        <div class="cart-content" tabIndex="-1">
          <ul class="mt-2" tabIndex="-1">
            <div
              v-if="!getCart || getCart.length == 0"
              class="d-flex align-items-start"
              tabIndex="-1"
            >
              <div class="col-12 mx-2" tabIndex="-1">
                Nenhum produto adicionado em seu carrinho
              </div>
            </div>

            <div v-else tabIndex="-1">
              <li v-for="(r, i) in getCart" :key="i" tabIndex="-1">
                <div class="d-flex align-items-start" tabIndex="-1">
                  <div
                    tabIndex="-1"
                    v-if="r.imagem[0] && r.imagem[0].link"
                    class="category-img"
                    :style="`background-image: url(${r.imagem[0].link})`"
                  ></div>
                  <div
                    tabIndex="-1"
                    v-else
                    class="category-img"
                    :style="`background-image: url(${r.imageThumbnail})`"
                  ></div>
                  <div tabIndex="-1">
                    <h1 class="h5" tabIndex="-1">{{ r.descricao }}</h1>
                    <div class="row mt-4 align-items-center" tabIndex="-1">
                      <div class="col-7 text-right" tabIndex="-1">
                        <b-input-group class="quantity-wrapper" tabIndex="-1">
                          <b-input-group-prepend tabIndex="-1">
                            <b-button
                              tabIndex="-1"
                              data-test="cart-quantity"
                              v-if="r.quantity == 1"
                              size="sm"
                              variant="outline-secondary"
                              @click.prevent="
                                removeProduct({ index: i, qtd: r.quantity });
                                validarCupom();
                              "
                            >
                              <b-icon icon="trash-fill" tabIndex="-1"></b-icon>
                            </b-button>

                            <b-button
                              tabIndex="-1"
                              data-test="cart-subtract"
                              v-else
                              size="sm"
                              variant="outline-secondary"
                              @click.prevent="
                                QuantitySubtract(i, r.quantity - 1, r)
                              "
                            >
                              <b-icon icon="dash"></b-icon>
                            </b-button>
                          </b-input-group-prepend>

                          <b-form-input
                            tabIndex="-1"
                            data-test="cart-quantity"
                            class="border-secondary quantity-input"
                            size="sm"
                            v-model="r.quantity"
                            type="number"
                            disabled
                            min="0.00"
                          ></b-form-input>

                          <b-input-group-append tabIndex="-1">
                            <b-button
                              tabIndex="-1"
                              data-test="cart-add"
                              size="sm"
                              variant="outline-secondary"
                              @click.prevent="QuantityAdd(i, r.quantity + 1, r)"
                              :disabled="
                                invalidProductList.includes(r.index)
                                  ? r.quantity >= 2
                                  : r.quantity >= 6
                              "
                            >
                              <b-icon icon="plus" tabIndex="-1"></b-icon>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>

                      <div class="col-5 text-left" tabIndex="-1">
                        <h5 class="mb-0" tabIndex="-1">
                          <span v-if="!r.precoPromocional" tabIndex="-1">
                            {{
                              (r.preco * r.quantity)
                                | currency("R$ ", 2, {
                                  thousandsSeparator: ".",
                                  decimalSeparator: ",",
                                })
                            }}
                          </span>
                          <span v-else tabIndex="-1">
                            <span class="normal-price" tabIndex="-1">
                              <span class="dashed" tabIndex="-1">
                                {{
                                  (r.preco * r.quantity)
                                    | currency("R$ ", 2, {
                                      thousandsSeparator: ".",
                                      decimalSeparator: ",",
                                    })
                                }}
                              </span>
                              <!-- <span class="pct">
                                <img src="@/assets/img/svg/arrow-down.svg" alt=""/>
                                <span>
                                  {{ calculateDiscountPercentage(r.preco, r.precoPromocional) }}%
                                </span>
                              </span> -->
                            </span>
                            <span class="promo-price" tabIndex="-1">
                              {{
                                (r.precoPromocional * r.quantity)
                                  | currency("R$ ", 2, {
                                    thousandsSeparator: ".",
                                    decimalSeparator: ",",
                                  })
                              }}
                            </span>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <div class="row" tabIndex="-1">
                <div class="col-6" tabIndex="-1">Valor Total</div>
                <div
                  class="col-6 text-right align-items-center d-flex justify-content-end"
                  tabIndex="-1"
                >
                  <strong tabIndex="-1">{{
                    getTotal
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}</strong>
                </div>
              </div>

              <b-button
                data-test="go-to-cart"
                variant="primary"
                @click="$router.push({ name: 'checkout' })"
                block
              >
                Ir para o carrinho
              </b-button>
            </div>
          </ul>
        </div>
      </b-sidebar>

      <div class="full-screen-bg t-sidebar" :class="menuIsOpen ? 'active' : ''">
        <div
          class="full-screen-bg mx-0 px-0"
          style="background: #f8f9fa !important"
        >
          <div class="header" style="background: #f8f9fa !important">
            <strong class="title">Menu</strong
            ><button
              data-test="sidebar-open-menu"
              type="button"
              @click="menuIsOpen = !menuIsOpen"
              class="close text-dark"
            >
              <svg
                viewBox="0 0 16 16"
                width="1em"
                height="1em"
                focusable="false"
                role="img"
                aria-label="x"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi-x b-icon bi"
              >
                <g>
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
          <div style="background: #f8f9fa !important">
            <b-button
              data-test="go-to-my-purchases"
              variant="primary"
              @click="
                [
                  (menuIsOpen = !menuIsOpen),
                  $router.push({ name: 'my-purchases' }),
                ]
              "
              block
            >
              Minhas Compras
            </b-button>

            <b-button
              data-test="go-to-my-coupons"
              variant="primary"
              @click="
                [
                  (menuIsOpen = !menuIsOpen),
                  $router.push({ name: 'my-coupons' }),
                ]
              "
              block
            >
              Meus Cupons
            </b-button>

            <b-button
              data-test="go-to-my-data"
              variant="primary"
              @click="
                [(menuIsOpen = !menuIsOpen), $router.push({ name: 'my-data' })]
              "
              block
            >
              Meus Dados
            </b-button>

            <ul style="background: #f8f9fa !important">
              <li class="title">Ajuda</li>
              <li
                data-test="video-usecoupons"
                @click="
                  [(menuIsOpen = !menuIsOpen), openVideoModal('use-coupons')]
                "
              >
                Como usar meus cupons?
              </li>
              <!-- <li data-test="video-track" @click="[(menuIsOpen = !menuIsOpen), openVideoModal('track')]">
                Como rastrear meus pedidos?
              </li> -->
              <!-- <li data-test="video-faq" @click="$router.push({ name: 'faq' })">
                Dúvidas frequentes
              </li>-->
              <li
                data-test="video-exchangereturn"
                @click="
                  [
                    (menuIsOpen = !menuIsOpen),
                    $router.push({ name: 'exchange-return' }),
                  ]
                "
              >
                Política de Troca e Devolução
              </li>
              <!-- <li data-test="my-account" @click="$router.push({ name: 'my-account' })">Sair</li> -->
            </ul>

            <b-button
              data-test="do-logoff"
              v-if="isLoggedIn && !isDeliveryPerson"
              variant="primary"
              @click="doLogoff()"
              block
              style="margin-top: auto"
            >
              Sair
            </b-button>
          </div>
        </div>
      </div>

      <div class="app-footer" v-if="!this.isCheckoutPage">
        <div class="container">
          <img
            data-test="app-footer-logo"
            @click.prevent="$router.push({ name: 'home' }).catch((err) => {})"
            src="@/assets/img/svg/ifood-logo.svg"
            alt=""
            class="logo"
          />

          <p>
            © Copyright 2021 - iFood - Todos os direitos reservados iFood com
            Agência de Restaurantes Online S.A. CNPJ 14.380.200/0001-21 /
            Avenida dos Autonomistas, nº 1496, Vila Yara, Osasco/SP - CEP
            06.020-902
          </p>
        </div>
      </div>

      <b-modal
        aria-modal
        id="use-coupons"
        title="Como usar meus cupons?"
        no-close-on-backdrop
        class="use-coupons"
      >
        <div class="content">
          <iframe
            class="youtube-video"
            src="https://www.youtube.com/embed/-lpp0C9PUVI?si=NZzjSl6nWwG_ZC_H"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <template #modal-footer="">
          <b-button
            data-test="hide-modal-use-coupons"
            size="sm"
            variant="danger"
            @click="$bvModal.hide('use-coupons')"
          >
            Voltar
          </b-button>
        </template>
      </b-modal>

      <!-- <b-modal
        aria-modal
        id="track"
        title="Como rastrear meus pedidos?"
        no-close-on-backdrop
        class="track"
      >
        <div class="content">
          <iframe
            class="youtube-video"
            src="https://www.youtube.com/embed/rMKGW5MLf9M?si=5c2h6Pd4urmw1HG7"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <template #modal-footer="">
          <b-button data-test="hide-modal-track" size="sm" variant="danger" @click="$bvModal.hide('track')">
            Voltar
          </b-button>
        </template>
      </b-modal> -->
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AppView",

  components: {},

  data() {
    return {
      searchInput: "",
      showSearch: false,
      showCart: false,
      isCheckoutPage: false,
      myCart: "",
      vl_cupom: 0,

      limitados: [
        "LJECX0000511",
        "LJEBD0000114",
        "LJEBGCJ00001",
        "LJEBGCJM0001",
        "LJEBGCJG0001",
        "LJEBGCJGG001",
        "LJEBGCJXG001",
        "LJEBGJQ00002",
        "LJEBGJQM0002",
        "LJEBGJQG0002",
        "LJEBGJQGG002",
        "LJEBGJQXG002",
        "LJEBGUV00003",
        "LJEBGUVM0003",
        "LJEBGUVG0003",
        "LJEBGUVGG003",
        "LJEBCJC00004",
        "LJEBCJCM0004",
        "LJEBCJCG0004",
        "LJEBCJCGG004",
        "LJEBCJCXG004",
        "LJEBGT000005",
        "LJEBGJQ00006",
        "LJEBGJQFP006",
        "LJEBGJQFM006",
        "LJEBGJQFG006",
        "LJEBGJQFGG06",
      ],
      invalidProductList: [
        "16017442516",
        "8777273697",
        "16017441081",
        "8777239210",
        "16017451238",
        "11496725662",
        "16017440194",
        "7980180076",
        "15971484833",
        "16019179083",
        "15971469850",
        "16019162559",
        "15971473105",
        "16019162565",
        "15971473106",
        "16019162566",
        "15971473103",
        "16019162562",
        "15970996679",
        "16019147484",
        "15971014307",
        "16019147488",
        "15971014310",
        "16019147490",
        "15971014304",
        "16019147486",
        "15971014312",
        "16019147495",
        "15971018111",
        "16019155851",
        "15971021025",
        "16019155855",
        "15971021027",
        "16019155857",
        "15971021022",
        "16019155852",
        "15971021029",
        "16019155859",
        "15971477766",
        "16019168028",
        "15971483881",
        "16019168030",
        "15971483882",
        "16019168032",
        "15971483880",
        "16019168029",
        "15973056583",
        "16019168033",
        "16029129587",
        "16029133402",
        "16029133409",
        "16029133400",
        "16029133398",
      ],

      menuIsOpen: false,
      hasPublicId: false,
      loginStatus: "",
      isLoggedIn: false,
      isDeliveryPerson: false,
      isRestaurant: false,
    };
  },

  computed: {
    ...mapGetters({
      productsList: "productsList",
      productsLoading: "productsLoading",
      myPurchaseList: "myPurchaseList",
      myPurchaseListLoading: "myPurchaseListLoading",
      getUser: "getUser",
      getCart: "getCart",
      getTotal: "getTotal",
    }),
  },

  async mounted() {
    document.addEventListener("click", this.closeAll);

    const sidebar = document.getElementById('mycart');
    if (sidebar) {
      sidebar.setAttribute('aria-hidden', 'false');
    }

    const getPublicIdFromURL = () => {
      const params = new URLSearchParams(window.location.search);
      return params.get("public_id");
    };

    const waitForUserId = async () => {
      while (!this.getUser?.id) {
        const publicId = getPublicIdFromURL();

        if (publicId) return publicId;

        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      return this.getUser.id;
    };

    try {
      const userId = await waitForUserId();

      await this.doLogin();

      this.userData = await this.fetchCustomer(userId);

      if (this.userData.type === "Restaurante") {
        this.isRestaurant = true;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } else {
        throw error;
      }
    }

    this.myCart = window.sessionStorage.getItem("cart");
    this.myCart = JSON.parse(this.myCart);

    this.$root.$on("showCartIcon", (param) => {
      this.isCheckoutPage = param;
    });
  },

  beforeDestroy() {
    document.removeEventListener("click", this.closeAll);
  },

  methods: {
    ...mapActions({
      changeQuantity: "changeQuantity",
      fetchProductsList: "fetchProductsList",
      fetchCustomer: "fetchCustomer",
      removeProduct: "removeProduct",
      fetchDeliveryPersonLogin: "fetchDeliveryPersonLogin",
    }),

    makeToast(title, text, variant, toaster) {
      this.toastCount++;
      this.$bvToast.toast(text, {
        title: title,
        autoHideDelay: 5000,
        variant: variant,
        toaster: toaster,
        appendToast: true,
      });
      var cartElement = document.getElementById("mycart");
      if (cartElement.style.display == "none") {
        this.$root.$emit("bv::toggle::collapse", "mycart");
      }
    },

    openSearch() {
      if (this.showCart) {
        this.$root.$emit("bv::toggle::collapse", "mycart");
        this.showCart = false;
      }
      this.showSearch = !this.showSearch;
    },

    openCart() {
      if (this.showSearch) {
        this.showSearch = false;
      }
      this.showCart = !this.showCart;
      this.$root.$emit("bv::toggle::collapse", "mycart");
    },

    closeSearch(event) {
      if (
        event.target.id !== "zoom" &&
        !event.target.closest(".search-wrapper")
      ) {
        this.showSearch = false;
      }
    },

    doSearch() {
      if (!this.searchInput) return;

      this.showSearch = false;

      if (this.$route.name === "product-list") {
        this.fetchProductsList({ name: this.searchInput, situacao: "Ativo" });
      } else {
        this.$router.push({
          name: "product-list",
          params: { name: this.searchInput },
        });
      }
    },

    closeAll(event) {
      if (
        this.showSearch &&
        !event.target.closest(".search-wrapper") &&
        event.target.id !== "zoom"
      ) {
        this.showSearch = false;
      }

      if (this.showCart && !event.target.closest(".minicart-wrapper")) {
        this.showCart = false;
        this.$root.$emit("bv::toggle::collapse", "mycart");
      }

      if (
        this.menuIsOpen &&
        !event.target.closest(".hamburger-menu") &&
        !event.target.closest(".hamb")
      ) {
        this.menuIsOpen = false;
      }
    },

    validateProduct(product) {
      const cart = this.getCart;

      if (cart.length === 0) return true;

      const specialCategories = ["PNEUS", "KITS", "ÓLEOS"];

      const specialItems = cart.filter(
        (item) =>
          this.invalidProductList.includes(item.id) ||
          specialCategories.includes(item.categoria.descricao)
      );

      const hasShoesInCart = cart.some(
        (item) => item.categoria.descricao === "CALÇADOS"
      );

      const isProductShoes = product.categoria.descricao === "CALÇADOS";

      const totalSpecialItems = specialItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      );

      const maxBags = this.isRestaurant ? 3 : 2;

      if (hasShoesInCart !== isProductShoes) {
        this.makeToast(
          "Erro",
          hasShoesInCart
            ? "Não podemos adicionar outros produtos à compra de calçados."
            : "Não podemos adicionar calçados à compra de outros produtos.",
          "error",
          "b-toaster-bottom-full"
        );
        return false;
      }

      if (
        (product.categoria.descricao === "BAG" &&
          product.quantity >= maxBags) ||
        (product.categoria.descricao === "KITS" && product.quantity >= maxBags)
      ) {
        this.makeToast(
          "Erro",
          "Quantidade máxima de produtos BAG atingida.",
          "error",
          "b-toaster-bottom-full"
        );
        return false;
      }

      if (
        specialCategories.includes(product.categoria.descricao) &&
        (product.quantity >= 2 || product.quantity + totalSpecialItems > 2)
      ) {
        this.makeToast(
          "Erro",
          "Quantidade máxima de produtos atingida.",
          "error",
          "b-toaster-bottom-full"
        );

        return false;
      }

      if (!this.invalidProductList.includes(product.id)) {
        const productInCart = cart.find((item) => item.id === product.id);

        if (productInCart && productInCart.quantity + product.quantity > 6) {
          this.makeToast(
            "Erro",
            "Quantidade máxima de produtos atingida.",
            "error",
            "b-toaster-bottom-full"
          );

          return false;
        }
      }

      return true;
    },

    QuantitySubtract(index, value, item) {
      let novo = JSON.parse(JSON.stringify(item));
      const price = item.precoPromocional
        ? parseFloat(item.precoPromocional)
        : parseFloat(item.preco);

      const qtdDiff = value - item.quantity;

      novo.quantity = value;

      this.changeQuantity({
        index: index,
        item: novo,
        qtd: qtdDiff,
        price: price * qtdDiff,
      });
    },

    QuantityAdd(index, value, item) {
      if (!this.validateProduct(item)) return;

      let novo = JSON.parse(JSON.stringify(item));
      const price = item.precoPromocional
        ? parseFloat(item.precoPromocional)
        : parseFloat(item.preco);

      const qtdDiff = value - item.quantity;

      novo.quantity = value;

      this.changeQuantity({
        index: index,
        item: novo,
        qtd: qtdDiff,
        price: price * qtdDiff,
      });
    },

    async validarCupom() {
      this.vl_cupom = 0;
      if (this.cupom) {
        try {
          this.coupon = await this.checkCupom({
            cupom: this.cupom.toUpperCase(),
            user: this.getUser.id ? this.getUser.id : this.getUser.user.id,
          });
        } catch (err) {
          this.makeToast(
            "Erro",
            "Cupom Inválido",
            "error",
            "b-toaster-bottom-full"
          );
        }
        if (!this.coupon.Message == "Cupom disponível para uso") {
          this.makeToast(
            "Erro",
            "Cupom Inválido",
            "error",
            "b-toaster-bottom-full"
          );
        } else {
          this.vl_cupom = 0;
          if (this.coupon.item.users) {
            const uuid = this.getUser.id
              ? this.getUser.id
              : this.getUser.user.id;
            if (this.coupon.item.users.includes(uuid)) {
              if (this.coupon.item.products) {
                for (var cont = 0; cont < this.getCart.length; cont++) {
                  if (
                    this.coupon.item.products.includes(
                      "" + this.getCart[cont].id
                    )
                  )
                    this.vl_cupom = this.coupon.item.value;
                }
                if (this.coupon.item.products[0] == "frete") {
                  if (this.retirar) this.vl_cupom = 0 + this.vl_cupom;
                  else this.vl_cupom = this.valorFrete + this.vl_cupom;
                }
              } else {
                this.vl_cupom = this.coupon.item.value;
              }
            } else {
              this.makeToast(
                "Erro",
                "Cupom Inválido",
                "error",
                "b-toaster-bottom-full"
              );
            }
          } else {
            if (this.coupon.item.products) {
              if (this.coupon.item.products[0] == "frete") {
                if (this.retirar) this.vl_cupom = 0 + this.vl_cupom;
                else this.vl_cupom = this.valorFrete + this.vl_cupom;
              } else {
                this.vl_cupom = this.coupon.item.value;
              }
            } else {
              this.vl_cupom = this.coupon.item.value;
            }
          }
        }
      }
    },

    openVideoModal(modal) {
      this.$bvModal.show(modal);
      this.$refs.sbMenu.hide();
    },

    async doLogin() {
      var userData = await this.$store.dispatch("restoreUser");

      if (!userData) {
        const queryString = decodeURI(window.location.search);
        const urlParams = new URLSearchParams(queryString);
        const publicIdFromUrl = urlParams.get("public_id");

        const apiKey = localStorage.getItem("api-key");
        const apiId = localStorage.getItem("api-id");
        const publicIdFromLS = localStorage.getItem("worker_id");

        if (publicIdFromUrl) {
          try {
            const body = {
              identification: publicIdFromUrl,
            };

            this.loginStatus = await this.fetchDeliveryPersonLogin(body);
            localStorage.setItem("api-key", this.loginStatus["api-key"]);
            localStorage.setItem("api-id", this.loginStatus["api-id"]);
            localStorage.setItem("worker_id", this.loginStatus.user.id);

            this.isLoggedIn = true;
            this.hasPublicId = true;
            this.isDeliveryPerson = true;
          } catch (error) {
            this.toastMessage = "Erro ao efetuar login.";
            this.isLoggedIn = false;
            this.hasPublicId = false;
            this.isDeliveryPerson = false;
          }
        } else if (apiKey && apiId && publicIdFromLS) {
          this.hasPublicId = true;
          this.isLoggedIn = true;
        } else {
          this.hasPublicId = false;
          this.isLoggedIn = false;
        }
      }
    },

    doLogoff() {
      localStorage.removeItem("api-key");
      localStorage.removeItem("api-id");
      localStorage.removeItem("worker_id");
      localStorage.removeItem("userData");

      this.isLoggedIn = false;

      if (this.$route.path !== "/") {
        this.$router.push("/");
      } else {
        this.$router.go(0);
      }
    },
  },
};
</script>

<style lang="scss">
#header {
  .mobile {
    display: none;
  }

  .search {
    img {
      width: 25px;
    }
  }

  .desk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .search {
      width: 100%;
      background: #ffffff;
      padding-left: 20px;
      margin: 0 40px;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        background: #eb0033;
        border-radius: 3px;
        margin: 0 10px;
        white-space: nowrap;
        color: #ffffff;
      }
    }

    &.lg {
      .left-wrapper,
      .center-wrapper,
      .right-wrapper {
        width: calc(100% / 3);

        display: flex;
        align-items: center;
      }

      .left-wrapper {
        justify-content: flex-start;
      }

      .center-wrapper {
        justify-content: center;
      }

      .right-wrapper {
        justify-content: flex-end;

        .search {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .desk {
      display: none;
    }
  }
}
#content {
  padding-top: 5rem;
  min-height: calc(100vh - 5rem);
}

.b-sidebar-outer {
  .b-sidebar {
    top: 65px;
  }
}

.minicart {
}

.b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 320px;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  outline: 0;
  transform: translateX(0);
  background-color: #f8f9fa !important;
  background: #f8f9fa !important;
  z-index: 999;
}

.full-screen-bg {
  height: 100%;
  width: 100%;
  background-color: #f8f9fa !important;
  background: #f8f9fa !important;
}

#mycart {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-width: 360px !important;
  height: unset;

  .b-sidebar-header {
    flex-direction: row-reverse;

    strong {
      margin-right: auto;
    }

    .close {
      margin-right: 0;
    }
  }

  .cart-content {
    padding: 0 15px 15px 15px;

    & > ul,
    & > ul li {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    & > ul {
      li {
        width: 100%;
        display: inline-block;
        padding: 1rem 1rem;
        border-bottom: 1px solid #a6a6a6;

        h5 {
          font-size: 1rem;
        }
      }
    }

    .category-img {
      min-width: 70px;
      width: 70px;
      height: 70px;
      border-radius: 5px;
      margin-right: 1rem;
      background: no-repeat center/contain;
    }

    ul {
      &.obs {
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0;
          border: none;
          font-size: 80%;
        }
      }
    }
  }
}

.b-side-bar-rivia {
  display: flex;
  flex-direction: column;
  width: 320px;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  outline: 0;
  position: fixed;
  top: 0;
  height: 100vh;
}

.bg-white-all {
  background-color: #f8f9fa !important;
  background: #f8f9fa !important;
}

.menu-rivia-sb-menu {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      font-weight: 500;
      margin: 15px 0;

      &.title {
        color: red;
      }

      &.sep {
        background: red;

        span {
          display: block;
          width: 100%;
          height: 2px;
          background: #d2d2d2;
        }
      }
    }
  }
}

.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.t-sidebar {
  position: fixed;
  top: 65px;
  left: -350px;
  z-index: 9999;
  /* height: 650px; */
  min-height: 650px;
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 320px;
  background-color: #f8f9fa !important;
  background: #f8f9fa !important;

  .header {
    font-size: 1.5rem;
    padding-bottom: 15px;

    .title {
      font-size: 1.5rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      font-weight: 500;
      margin: 15px 0;
      color: #343a40;

      &.title {
        color: red;
      }
    }
  }

  &.active {
    left: 0;
  }

  .breadcrumb {
    background-color: unset !important;
    color: #eb0033;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    span {
      margin: 0 5px;
    }
  }
}

.app-footer {
  width: 100%;
  background: #f2f2f2;

  @media (max-width: 768px) {
    display: none !important;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;

    img {
      margin-right: 30px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      color: #7e817e;

      margin: 0;
    }
  }
}

.normal-price {
  font-size: 18px;
  display: block;

  display: flex;
  align-items: center;
  justify-self: center;

  .dashed {
    text-decoration: line-through;
    font-size: 10px;

    white-space: nowrap;
  }

  .pct {
    background: rgba(44, 192, 73, 1);
    border-radius: 12.5px;
    padding: 3px 15px;
    color: #ffffff;
    font-size: 14px;

    margin-left: 5px;

    display: flex;
    align-items: center;
    justify-self: center;

    img {
      margin-right: 5px;
    }
  }
}

.promo-price {
  .por {
    font-size: 14px;
  }
}
</style>